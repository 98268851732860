export default class BackgroundImageFallback {
	constructor(el) {
		this.element = el;
		this.objectFitIsSupported =
			'objectFit' in document.documentElement.style === true;
		this.supportsBackgroundBlendMode =
			typeof window.getComputedStyle(document.body).backgroundBlendMode !==
			'undefined';

		if (!this.supportsBackgroundBlendMode) {
			this.processElementBlend();
			return;
		}
		if (this.objectFitIsSupported) {
			this.objectFit();
		}
	}

	processElementBlend() {
		this.img =
			this.element.tagName === 'PICTURE'
				? this.element.querySelector('img')
				: this.element;
		const style = window.getComputedStyle(this.element);
		const backgroundImageURL = this.img.currentSrc;
		const backgroundColor = style.backgroundColor;

		this.createBlendedBackgroundImageFromURLAndColor(
			backgroundImageURL,
			backgroundColor,
			this.setBackground.bind(this)
		);
	}
	createBlendedBackgroundImageFromURLAndColor(url, color, callback) {
		const img = document.createElement('img');

		img.src = url;
		img.onload = function () {
			let canvas = document.createElement('canvas');

			canvas.width = this.naturalWidth;
			canvas.height = this.naturalHeight;
			let context = canvas.getContext('2d');

			context.globalCompositeOperation = 'multiply';
			context.drawImage(this, 0, 0);
			context.fillStyle = color;
			context.fillRect(0, 0, canvas.width, canvas.height);
			let data = canvas.toDataURL('image/jpeg');

			callback(data);
		};
	}
	objectFit() {
		this.img = this.element.querySelector('img');

		this.img.addEventListener('load', () => {
			this.setBackground();
		});

		if (this.img.complete) {
			this.setBackground();
		}
	}
	getSrc() {
		return typeof this.img.currentSrc !== 'undefined'
			? this.img.currentSrc
			: this.img.src;
	}
	setBackground(src = this.getSrc()) {
		this.img.style.display = 'none';
		this.element.style.backgroundImage = `url("${src}")`;
	}
}
