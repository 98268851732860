import { h } from 'preact';
import { Link } from 'preact-router/match';
import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';

const Navbar = ({
	navMenuItems,
	toggleSidebarState,
	scrollToSection,
	currentActiveSection,
	forceCollabsed,
	urlPath,
}) => {
	const sideCollapse = useRef(null);
	const sideBarToggle = useRef(null);
	const isActive = link =>
		currentActiveSection === link || (urlPath === link && !currentActiveSection)
			? ' active'
			: '';
	const navItemClickHandler = e => {
		e.preventDefault();
		e.stopImmediatePropagation();
		scrollToSection(e.target.pathname.replace('/', ''));
	};

	useEffect(() => {
		const toggler = sideBarToggle.current;
		const toglleSidebar = () => {
			toggleSidebarState();
		};
		if ((menuItems.length = 0)) return;

		toggler.addEventListener('click', toglleSidebar);

		return () => {
			toggler.removeEventListener('click', toglleSidebar);
		};
	}, [toggleSidebarState, scrollToSection]);

	const menuItems = navMenuItems.map(item => (
		<li class="nav-item">
			<Link
				onClick={
					item.clickHandler
						? () => {
								item.clickHandler(item.name);
								toggleSidebarState();
						  }
						: navItemClickHandler
				}
				class={`nav-link${isActive(item.link)}`}
				href={item.link}
			>
				{item.name}
			</Link>
		</li>
	));

	useEffect(() => {
		if (!sideBarToggle.current) return;
		if (menuItems.length === 0) sideBarToggle.current.style.display = 'none';
		if (menuItems.length !== 0) sideBarToggle.current.style.display = '';
	}, [menuItems, menuItems.length]);

	return (
		<nav
			class={`navbar navbar-expand-md navbar-dark fixed-top${
				forceCollabsed ? ' navbar-shrink' : ''
			}`}
			id="mainNav"
		>
			<div class="container">
				<a
					onClick={e => {
						e.preventDefault();
						e.stopImmediatePropagation();
						if (!forceCollabsed) {
							scrollToSection(e.target.pathname.replace('/', ''));
						} else {
							route('/');
						}
					}}
					class="navbar-brand"
					href="/Hero"
				>
					marcoSven
				</a>
				<button
					ref={sideBarToggle}
					class="navbar-toggler navbar-toggler-right"
					type="button"
					aria-controls="navbarResponsive"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<svg class="si" width="25" height="25">
						<use xlinkHref="#si-ionic-android-menu" />
					</svg>
				</button>

				<div ref={sideCollapse} class="side-collapse" id="navbarResponsive">
					<ul class="navbar-nav text-uppercase ml-auto">{menuItems}</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
