import { h, Component, createRef } from 'preact';
import { Router } from 'preact-router';
import throttle from 'lodash/throttle';
import ReactGA from 'react-ga';

import NavBar from './navbar';
import Home from '../routes/home';
import Travel from '../routes/travel';
import CSSART from '../routes/cssArt';

ReactGA.initialize('UA-199089269-1');

export default class App extends Component {
	state = {
		pageSections: {},
		currentActiveSection: undefined,
		navMenuItems: [],
		navbarForceCollabsed: null,
		urlPath: '',
	};
	isSidebarOpen = false;
	navBar = createRef();
	toggleSidebarState = () => {
		document.querySelector('body').classList.toggle('sidebar-is-open');
		this.isSidebarOpen = !this.isSidebarOpen;
	};

	setSections = (sections, navMenuItems) => {
		this.setState({ pageSections: sections, navMenuItems });
		if (Object.keys(this.state.pageSections).length === 0) {
			this.scrollHandler();
		}
	};
	scrollToSection = target => {
		if (this.state.pageSections[target]) {
			this.state.pageSections[target].scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
			if (this.isSidebarOpen) {
				this.toggleSidebarState();
			}
		}
	};
	getActiveSection() {
		const sections = Object.values(this.state.pageSections);
		const sectionMargin = 300;

		return Object.keys(this.state.pageSections)[
			sections.length -
				[...sections]
					.reverse()
					.findIndex(
						section =>
							section && window.scrollY >= section.offsetTop - sectionMargin
					) -
				1
		];
	}
	setActiveSection = current => {
		if (current !== this.state.currentActiveSection) {
			if (typeof window !== 'undefined') {
				ReactGA.pageview(window.location.pathname, current, current);
			}
			this.setState({ currentActiveSection: current });
		}
	};
	navbarCollapse = throttle(() => {
		const navBar = this.navBar.current.base;

		if (navBar && navBar.getBoundingClientRect().top + window.scrollY > 100) {
			navBar.classList.add('navbar-shrink');
		} else if (navBar && !this.state.navbarForceCollabsed) {
			navBar.classList.remove('navbar-shrink');
		}
	}, 300);
	activeSection = throttle(() => {
		const current = this.getActiveSection();
		this.setActiveSection(current);
	}, 300);

	scrollHandler = () => {
		this.navbarCollapse();
		this.activeSection();
	};
	componentDidMount() {
		window.addEventListener('scroll', this.scrollHandler);
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandler);
	}
	componentDidUpdate() {
		if (this.state.navbarForceCollabsed) {
			window.removeEventListener('scroll', this.scrollHandler);
		} else {
			window.addEventListener('scroll', this.scrollHandler);
		}
	}
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		if (typeof window !== 'undefined') {
			ReactGA.pageview(window.location.pathname);
		}
		this.setState({
			navbarForceCollabsed: e.url !== '/',
			urlPath: e.url,
			currentActiveSection: undefined,
		});
	};

	render() {
		return (
			<div id="app">
				<NavBar
					ref={this.navBar}
					navMenuItems={this.state.navMenuItems}
					forceCollabsed={this.state.navbarForceCollabsed}
					urlPath={this.state.urlPath}
					currentActiveSection={this.state.currentActiveSection}
					toggleSidebarState={this.toggleSidebarState}
					scrollToSection={this.scrollToSection}
				/>
				<Router onChange={this.handleRoute}>
					<Home
						currentActiveSection={this.state.currentActiveSection}
						setSections={this.setSections}
						scrollToSection={this.scrollToSection}
						path="/"
					/>
					<Travel
						setSections={this.setSections}
						setActiveSection={this.setActiveSection}
						searchbarElementHeight="58"
						path="/travel/"
					/>

					<Travel
						setSections={this.setSections}
						setActiveSection={this.setActiveSection}
						searchbarElementHeight="58"
						path="/travel/:album"
					/>
					<CSSART
						setSections={this.setSections}
						setActiveSection={this.setActiveSection}
						searchbarElementHeight="58"
					 	path="/css-art/strawberry-popsicle" />
				</Router>
			</div>
		);
	}
}
