import webVitals from './helper/web-vitals-import';
import BackgroundImageFallback from './helper/background-image-fallback';
import colorCycle from './helper/color-cycle';
import './style';
import App from './components/app';

// eslint-disable-next-line no-undef
if (DEV_ENV) {
	webVitals();
}

if (typeof window !== 'undefined') {
	window.BackgroundImageFallback = BackgroundImageFallback;
	colorCycle();
}

export default App;
