const colorCycle = function () {
	const colors = ['#36d6fe', '#38c593', '#fed136'];
	const body = document.querySelector('body');
	let i = 0;
	setInterval(() => {
		body.classList.add('color-transition');

		document.documentElement.style.setProperty('--ms__primary', colors[i]);

		document.documentElement.style.setProperty(
			'--ms__primary__darken',
			shadeBlend(-0.2, colors[i])
		);

		let rgb = hexToRgb(colors[i]);

		document.documentElement.style.setProperty(
			'--ms__primary__opac',
			`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7)`
		);
		i = i === 2 ? 0 : ++i;

		setTimeout(() => {
			body.classList.remove('color-transition');
		}, 2000);
	}, 18588);
}

function shadeBlend(p, c0, c1) {
	let n = p < 0 ? p * -1 : p,
		u = Math.round,
		w = parseInt;

	if (c0.length > 7) {
		const f = c0.split(','),
			t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(','),
			R = w(f[0].slice(4)),
			G = w(f[1]),
			B = w(f[2]);

		return `rgb(${u((w(t[0].slice(4)) - R) * n) + R},${
			u((w(t[1]) - G) * n) + G
		},${u((w(t[2]) - B) * n) + B})`;
	}
	const f = w(c0.slice(1), 16),
		t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16),
		R1 = f >> 16,
		G1 = (f >> 8) & 0x00ff,
		B1 = f & 0x0000ff;

	return `#${(
		0x1000000 +
		(u(((t >> 16) - R1) * n) + R1) * 0x10000 +
		(u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
		(u(((t & 0x0000ff) - B1) * n) + B1)
	)
		.toString(16)
		.slice(1)}`;
}

function hexToRgb(hex) {
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result
		? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		  }
		: null;
}

export default colorCycle;